<template>
  <CRow>
    <CCol col="12" xl="12">
      <CButton
        variant="outline"
        type="submit"
        color="primary"
        class="list-new-btn"
        @click="$router.push({ path: '/manager/locations/create' })"
      >
        <CIcon name="cil-check-circle" />New location
      </CButton>

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Locations</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              sorter
              striped
              table-filter
              :items="items"
              :fields="fields"
              :items-per-page="perPage"
              :pagination="$options.paginationProps"
              index-column
            >
              <template #actions="{ item, index }">
                <td class="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    @click="detailClicked(item, index)"
                  >
                    Details
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    @click="
                      $router.push({ path: `/customer-booking/${item.id}/qr` })
                    "
                  >
                    QR
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import LocationAPI from "/app/src/api/location.js";

export default {
  name: "Locations",
  data: () => {
    return {
      items: [],
      fields: [
        { key: "name" },
        { key: "location_city" },
        { key: "location_telephone" },
        { key: "location_mail" },
        { key: "actions" },
      ],
      perPage: 20,
    };
  },
  paginationProps: {
    align: "center",
    doubleArrows: false,
    previousButtonHtml: "prev",
    nextButtonHtml: "next",
  },
  created: function () {
    this.getLocations();
  },
  methods: {
    getLocations: function () {
      this.$store.dispatch("loading");
      let locationAPI = new LocationAPI();
      let self = this;
      locationAPI
        .all()
        .then((locations) => {
          console.log(locations);
          this.$store.dispatch("stopLoading");
          self.items = locations;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
        });
    },
    detailClicked(item, index) {
      const itemLink = `/manager/locations/${item.id.toString()}`;
      this.$router.push({ path: itemLink });
    },
  },
};
</script>
